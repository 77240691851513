(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ImportConstructelDetailController', ImportConstructelDetailController);

    ImportConstructelDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'ImportConstructel', 'Intervention'];

    function ImportConstructelDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, ImportConstructel, Intervention) {
        var vm = this;


        vm.predicate = 'id';
        vm.reverse = false;
        vm.importConstructel = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.transition = transition;

        vm.filterComparaisonStatus = null;

        getCorrespondingInterventions();

        var unsubscribe = $rootScope.$on('tmgApp:importConstructelUpdate', function(event, result) {
            vm.importConstructel = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function transition() {
            getCorrespondingInterventions();
        }

        function getCorrespondingInterventions() {
            Intervention.query({
                sort: sort(),
                comparaisonStatus: vm.filterComparaisonStatus,
                constructelFilename: vm.importConstructel.name,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                result.push('defaultSort,asc');
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                console.log(result)
                return result;
            }
            function onSuccess(data, headers) {
                vm.interventionsFound = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

		vm.download = function(){
			 ImportConstructel.downloadURL({
				id: vm.importConstructel.id
            }, function(body){
				DataUtils.openURL(body.downloadURL);
			});
		};
    }
})();
