(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('InterventionController', InterventionController);

    InterventionController.$inject = ['$state', 'Intervention', 'Technicien', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function InterventionController($state, Intervention, Technicien,ParseLinks, AlertService, paginationConstants, pagingParams) {
        $(document).ready(function () {
            $('#filterTechnicien').select2();u
            $('#filterTechnicienEquipe').select2();
        });
        $('#filterTechnicien').on('select2:select', function (e) {
            vm.search();
        });
        $('#filterTechnicienEquipe').on('select2:select', function (e) {
            vm.search();
        });
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        if(typeof pagingParams.status =='string'){
        	vm.filterStatus = [pagingParams.status];
        }else{
        	vm.filterStatus = pagingParams.status;
        }
        if(typeof pagingParams.comparaisonStatus =='string'){
        	vm.filterComparaisonStatus = [pagingParams.comparaisonStatus];
        }else{
        	vm.filterComparaisonStatus = pagingParams.comparaisonStatus;
        }
        vm.filterSearch = pagingParams.search;
        vm.filterDateFrom = pagingParams.dateFrom;
        vm.filterDateTo = pagingParams.dateTo;
        vm.filterTechnicien = pagingParams.technicien;
        vm.filterTechnicienEquipe = pagingParams.technicienEquipe;
        vm.filterTailleEquipe = pagingParams.tailleEquipe;
        vm.filterTechnicienRole = pagingParams.technicienRole;
        vm.filterConstructelFilename = pagingParams.constructelFilename;
        vm.filterDateMailImport = pagingParams.dateMailImport;
        vm.filterFilename = pagingParams.filename;
        vm.filterBonDeCommande = pagingParams.bonDeCommande;

        vm.displayReo=true;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.manualClose = manualClose;
        vm.facturationFight = facturationFight;
        vm.resetBonusMalus = resetBonusMalus;
        vm.resetBonusMalusIntervention = resetBonusMalusIntervention;
        vm.getTotalPrice = getTotalPrice;
        vm.selectPhoneNumber = selectPhoneNumber;

        loadAll();
        loadTechniciens();

        function loadAll () {
            Intervention.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                search: vm.filterSearch,
                status: vm.filterStatus,
                comparaisonStatus: vm.filterComparaisonStatus,
                dateFrom: vm.filterDateFrom,
                dateTo: vm.filterDateTo,
                technicien: vm.filterTechnicien,
                technicienEquipe: vm.filterTechnicienEquipe,
                tailleEquipe: vm.filterTailleEquipe,
				technicienRole: vm.filterTechnicienRole,
                constructelFilename: vm.filterConstructelFilename,
                dateMailImport: vm.filterDateMailImport,
                filename: vm.filterFilename,
                bonDeCommande: vm.filterBonDeCommande,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                result.push('defaultSort,asc');
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.interventions = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadTechniciens(){
        	Technicien.technicienEnabledHistory({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function search(){
        	vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current,  Object.assign(createInterventionFilter(), {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }));
        }

		function createInterventionFilter(){
			return {
                search: vm.filterSearch,
                status: vm.filterStatus,
                comparaisonStatus: vm.filterComparaisonStatus,
                dateFrom: vm.filterDateFrom,
                dateTo: vm.filterDateTo,
                technicien: $('#filterTechnicien').val(),
                technicienEquipe: $('#filterTechnicienEquipe').val(),
				technicienRole: $('#filterTechnicienRole').val(),
                tailleEquipe: vm.filterTailleEquipe,
                constructelFilename: vm.filterConstructelFilename,
                dateMailImport: vm.filterDateMailImport,
                filename: vm.filterFilename,
                bonDeCommande: vm.filterBonDeCommande,
            }
		}

        vm.datePickerOpenStatus.creationDate = false;
        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function manualClose(id){
        	Intervention.manualClose({id:id},function(s){
        		loadAll();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function facturationFight(id) {
            Intervention.facturationFight({id:id},function(s){
                loadAll();
            }, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function getTotalPrice(){
        	var totalPrice=0;
        	if(vm.interventions){
        		vm.interventions.forEach(function(intervention) {
        			if(intervention.price){
        				totalPrice+=intervention.price;
        			}
        		});
        	}
        	return totalPrice;
        }

        function selectPhoneNumber(phoneNumber){
        	vm.filterSearch=phoneNumber;
        	vm.filterStatus=null;
        	vm.filterComparaisonStatus=null;
        	search();
        }

        vm.reset = function(){
        	 $state.transitionTo($state.$current, {
                 page: vm.page,
                 sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                 search: vm.filterSearch
             });
        }

        vm.getCssClass=function(intervention){
        	var cssClass='';
        	if(intervention.reo){
        		cssClass+=' warning';
        	}else{
        		for (var i = 0; i < intervention.activities.length; i++) {
        			var activitie=intervention.activities[i];
//        			if(activitie.code.indexOf('BONUS')>0){
//                		cssClass+=' success';
//        			}
        		}
        	}
        	return cssClass;
        }

        function resetBonusMalus(){
        	Intervention.bonusMalus(createInterventionFilter(),function(s){
        		loadAll();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function resetBonusMalusIntervention(){
        	var interventionId=vm.interventions[vm.interventions.length-1].id;
        	Intervention.bonusMalusIntervention({id:interventionId},function(s){
        		loadAll();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        vm.arround = function(i) {
            return Math.ceil(i);
        }
    }
})();
