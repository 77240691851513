(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('EquipeController', EquipeController);

    EquipeController.$inject = ['$state', '$scope', 'Equipe', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Technicien', 'GroupePlanning', 'Outil'];

    function EquipeController($state, $scope, Equipe, ParseLinks, AlertService, paginationConstants, pagingParams, Technicien, GroupePlanning, Outil) {
        var vm = this;

        vm.equipes = {};
        vm.outils = [];
        vm.filterYear = null;
        vm.filterWeek = null;
        vm.filterDate = null;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.yearDispo = [];
        vm.parseNumberToMonth = parseNumberToMonth;
        vm.copyToNextDay = copyToNextDay;
        vm.copyToAllWeek = copyToAllWeek;
        vm.copyErrorMsg = "";
        vm.colors = null;
        vm.openModal = openModal;
        vm.filterByWeekNumber = filterByWeekNumber;
        vm.filterByDate = filterByDate;
        vm.isGroupeEmpty = isGroupeEmpty;
        vm.isShowOutilTrue = isShowOutilTrue;

        vm.getTechNumberForDay = getTechNumberForDay;

        vm.currentWeek = pagingParams.dateCalendar ? getWeekFromDate(getStartOfWeek(new Date(pagingParams.dateCalendar))) : getWeekFromDate(getStartOfWeek(new Date()));
        vm.weekNumber = getWeekNumber(new Date(vm.currentWeek[0]));

        loadAll();
        loadOutil();
        loadTechniciens();
        loadAvailableTechniciensByDate();
        loadGroupesPlanning();
        createArrayOfYear();
        defineColors();
        function isGroupeEmpty(id, date) {
            var formatDate = date.toDateString();
            if (vm.equipes[formatDate] !== undefined){
                if (vm.equipes[formatDate][id] !== undefined){
                    return false;
                }
            }
            return true;
            // //si on ne doit pas afficher le groupe uniquement lorsqu'il n'est pas present durant toute la semaine:
            // for (var date in vm.equipes) {
            //     if (vm.equipes[date][id]) {
            //         return false;
            //     }
            // }
            // return true;
        }

        function openModal(id){
            var modalInstance = $uibModal.open({
                template: '<div class="modal-header"><h3>Titre de ma modal</h3></div><div class="modal-body">Contenu de ma modal...</div><div class="modal-footer"><button class="btn btn-primary" ng-click="vm.closeModal()">OK</button></div>',
                controller: 'EquipeController as vm'
            });

            modalInstance.result.then(function() {}, function() {});
        }

        function closeModal(id) {
            $uibModalInstance.close();
        }

        function copyToAllWeek(date) {
            var formattedDate = moment(new Date(date)).format('YYYY-MM-DD');
            Equipe.copyToAllWeek({
                    date: formattedDate,
                },
                function onSuccess(data) {
                    if (data.length > 0) {
                        showCopyErrorMsg(data);
                    }
                    loadAll();
                },
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            );
        }

        function copyToNextDay(date) {
            var formattedDate = moment(new Date(date)).format('YYYY-MM-DD');
            Equipe.copyToNextDay({
                    date: formattedDate,
                },
                function onSuccess(data) {
                    if (data.length > 0) {
                        showCopyErrorMsg(data);
                    }
                    loadAll();
                },
                function onError(error) {
                    AlertService.error(error.data.message);
                });
        }

        function showCopyErrorMsg(data) {
            if(data.length > 1) {
                vm.copyErrorMsg = "Les techniciens " + data.join(", ") + " ne sont pas disponibles pour la journée selectionnée.";
            } else if(data.length === 1){
                vm.copyErrorMsg = "Le technicien " + data.join(", ") + " n'est pas disponible pour la journée selectionnée.";
            }

            setTimeout(function() {
                vm.copyErrorMsg = "";
                $scope.$apply();
            }, 30000);
        }

        function getWeekNumber(date){
            date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

            date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));

            var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));

            return Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
        }

        function filterByWeekNumber() {
            var yearStart;
            if(vm.filterYear != null){
                yearStart = new Date(vm.filterYear, 0, 1);
            }else{
                var now = new Date();
                yearStart = new Date(now.getFullYear(), 0, 1);
            }
            var msPerDay = 24 * 60 * 60 * 1000;

            // Trouver le premier jeudi de l'année
            var thursdayOfYear = yearStart;
            while (thursdayOfYear.getDay() !== 4) {  // 4 est pour jeudi
                thursdayOfYear = new Date(thursdayOfYear.getTime() + msPerDay);
            }

            // Calculer le premier lundi avant ou le jour de ce jeudi
            var mondayOfYear = new Date(thursdayOfYear.getTime() - (thursdayOfYear.getDay() - 1) * msPerDay);

            // Calculer le nombre de jours depuis ce lundi
            var daysFromFirstMonday = (vm.filterWeek - 1) * 7;
            var newDate = new Date(mondayOfYear.getTime() + daysFromFirstMonday * msPerDay);

            pagingParams.dateCalendar = moment(newDate).format("YYYY-MM-DD");
            $state.transitionTo($state.$current, {
                dateCalendar: pagingParams.dateCalendar
            });
        }

        function filterByDate() {
            pagingParams.dateCalendar = moment(vm.filterDate).format("YYYY-MM-DD");
            $state.transitionTo($state.$current, {
                dateCalendar: pagingParams.dateCalendar
            });
        }

        function getStartOfWeek(date) {
            var day = date.getDay();
            var difference = date.getDate() - day + (day === 0 ? -6 : 1);
            return new Date(date.setDate(difference));
        }

        function getWeekFromDate(startDate) {
            var week = [];
            for (var i = 0; i < 6; i++) {
                var newDate = new Date(startDate);
                newDate.setDate(newDate.getDate() + i);
                week.push(newDate);
            }
            return week;
        }

        vm.nextWeek = function () {
            var newDate = new Date(vm.currentWeek[0]);
            newDate.setDate(newDate.getDate() + 7);
            pagingParams.dateCalendar = moment(newDate).format("YYYY-MM-DD");
            $state.transitionTo($state.$current, {
                dateCalendar: pagingParams.dateCalendar
            });
        };

        vm.previousWeek = function () {
            var newDate = new Date(vm.currentWeek[0]);
            newDate.setDate(newDate.getDate() - 7);
            pagingParams.dateCalendar = moment(newDate).format("YYYY-MM-DD");
            $state.transitionTo($state.$current, {
                dateCalendar: pagingParams.dateCalendar
            });
        };

        vm.actualWeek = function () {
            var newDate = new Date();
            pagingParams.dateCalendar = moment(newDate).format("YYYY-MM-DD");
            $state.transitionTo($state.$current, {
                dateCalendar: pagingParams.dateCalendar
            });
        };

        vm.getTeamForDate = function(date) {
            for (var i = 0; i < vm.equipes.length; i++) {
                if (vm.equipes[i].date.toDateString() === date.toDateString()) {
                    return vm.equipes[i];
                }
            }
            return null;
        };

        function loadAll() {
            Equipe.getCurrentWeek({
                start: vm.currentWeek[0].toISOString().split('T')[0],
                end: vm.currentWeek[vm.currentWeek.length - 1].toISOString().split('T')[0]
            },
            function onSuccess(data, headers) {
                vm.equipes = {};
                data.forEach(function(equipe) {
                    var date = new Date(equipe.date).toDateString();
                    var groupePlanningId = equipe.groupePlanningId;
                    if (vm.equipes[date] === undefined) {
                        vm.equipes[date] = {};
                    }
                    if (groupePlanningId !== null){
                        if (vm.equipes[date][groupePlanningId] === undefined) {
                            vm.equipes[date][groupePlanningId] = [];
                        }
                        vm.equipes[date][groupePlanningId].push(equipe);
                    }else {
                        if (vm.equipes[date]['withoutGroupePlanning'] === undefined) {
                            vm.equipes[date]['withoutGroupePlanning'] = [];
                        }
                        vm.equipes[date]['withoutGroupePlanning'].push(equipe);
                    }
                });
            },
            function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function loadOutil() {
            return Outil.getEnabled({ }, function onSuccess(data, headers) {
                data.forEach(function(outil){
                    outil.dropdownDisplay = outil.code + ': ' + outil.name;
                });
                vm.outils = data;

            }, function onError(error) {
                AlertService.error(error.data.message);
            }).$promise;
        }

        vm.getTeamsForDate = function(date) {
            var teams = [];
            for (var i = 0; i < vm.equipes.length; i++) {
                if (new Date(vm.equipes[i].date).toDateString() === date.toDateString()) {
                    teams.push(vm.equipes[i]);
                }
            }
            return teams;
        };

        function getTechNumberForDay(day) {
            var equipesOfTheDay = vm.equipes[day.toDateString()];
            var techNumber = 0;
            if (equipesOfTheDay) {
                for (var groupe in equipesOfTheDay) {
                    var equipes = equipesOfTheDay[groupe];

                    for (var i = 0; i < equipes.length; i++) {
                        techNumber++;

                        if (equipes[i].binome) {
                            techNumber++;
                        }
                    }
                }
            }
            return techNumber;
        }

        function isShowOutilTrue(code) {
            for (var i = 0; i < vm.outils.length; i++) {
                var outil = vm.outils[i];
                if (outil.code === code) {
                    return outil.showPlanning === true;
                }
            }

            return false;
        }

        vm.formatDate = function(date){
            return new Date(date).toISOString().split('T')[0];
        };

        vm.formatTechName = function(technicien) {
            var parts = technicien.split(" ");
            var prenom = parts[0];
            var nom = parts[1];
            return prenom + " " + nom.charAt(0);
        };

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function loadAvailableTechniciensByDate() {
            Technicien.getAvailableTechByDatesForPlanning({
                dateStart: vm.currentWeek[0].toISOString().split('T')[0],
                dateEnd: vm.currentWeek[vm.currentWeek.length - 1].toISOString().split('T')[0]
            }, function onSuccess(data, headers) {
                vm.availableTechniciensDates = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function loadTechniciens(){
            Technicien.technicienEnabled({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function loadGroupesPlanning() {
            GroupePlanning.getEnabled({ }, function onSuccess(data, headers) {
                vm.groupesPlanning = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function defineColors(){
            vm.colors = {
                Gris: {
                    title: "#8C8C8B",
                    base: "#BDBDBD",
                },
                Bleu: {
                    title: "#6590EB",
                    base: "#A5C0FA"
                },
                Vert: {
                    title: "#58BF64",
                    base: "#9BE8A5"
                },
                Rouge: {
                    title: "#F56464",
                    base: "#FCB1B1"
                },
                Jaune: {
                    title: "#FAE950",
                    base: "#FAEE87"
                },
                Violet: {
                    title: "#D159DE",
                    base: "#E797F0"
                },
                Orange: {
                    title: "#FFB061",
                    base: "#FFCA96"
                }
            };
        }

        function parseNumberToMonth(month) {
            var today = Date.now();
            var date = moment(today);
            date.set('Month',month);
            return date.format("MMMM");
        }

        function createArrayOfYear() {
            var currentDate = new Date();
            for (var i = 2020; i <= currentDate.getFullYear()+1 ; i++) {
                vm.yearDispo.push(i);
            }
        }

        vm.getTooltipContent = function(outils) {
            return outils.join('\n');
        };

        setTimeout(function () {
            var tooltipTriggerList = Array.prototype.slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            tooltipTriggerList.forEach(function (tooltipTriggerEl) {
                new bootstrap.Tooltip(tooltipTriggerEl);
            });
        }, 0);
    }
})();
