(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ImportConstructelNewDialogController', ImportConstructelNewDialogController);

    ImportConstructelNewDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ImportConstructel','$http', 'DataUtils'];

    function ImportConstructelNewDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ImportConstructel,$http, DataUtils) {
        var vm = this;

        vm.importConstructel = entity;
        vm.clear = clear;
        vm.save = save;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.importConstructel.files.forEach(function(file){
                file.typeImport = vm.importConstructel.typeImport;
            })
            ImportConstructel.saveNewFiles(vm.importConstructel.files, onSaveSuccess, onSaveError);
        }

        vm.setFiles = function ($files, importFiles) {
            if ($files && $files.length) {
                $files.forEach(function(file) {
                    DataUtils.toBase64(file, function(base64Data) {
                        $scope.$apply(function() {
                            var importFile = {
                                fileName: file.name,
                                file: base64Data,
                                fileContentType: file.type
                            };
                            importFiles.files.push(importFile);
                        });
                    });
                });
            }
            console.log(importFiles);
        };

        vm.removeFile = function (file) {
            var index = vm.importConstructel.files.indexOf(file);
            if (index !== -1) {
                vm.importConstructel.files.splice(index, 1);
            }
        };

        function onSaveSuccess (result) {
            $scope.$emit('tmgApp:importConstructelUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
